
import {Component} from '@angular/core';

@Component({
  template: `
  <ddSeriesCarousel></ddSeriesCarousel>`,
  styles: []
})

export class SeriesPageComponent {

}
